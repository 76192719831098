////////////////////////////////////////////////////////////////////
// Header Logo
////////////////////////////////////////////////////////////////////
header {
  background-color: $header-background-color;
  .inside {
    position: relative;
    left: 0;
    top: 0;
    z-index: 1;
    border-bottom: 10px solid $dark-gray;
    -webkit-box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.3);
    @include media($md-screen-up) {
      height: 133px;
    }
    #logo_desktop {
      @include media ($md-screen-up) {
        display: none;
      }
      img {
        height: 56px;
        width: auto;
      }
      img {
        @include media ($xs-screen-up) {
          height: 123px;
        }
      }
    }
    #logo_mobile {
      display: inline;
      text-align: center;
      img {
        width: 179px;
        padding: 20px 20px 15px 20px;
      }
      @include media ($xs-screen-up) {
        img {
          width: 256px;
        }
      }

      @include media ($md-screen-up) {
        display: none;
      }
    }
  }
  p {
    margin: 0;
    padding: 0 20px;
    display: block;
    @include outer-container();
    @include media($lg-screen-up) {
      padding: 0;
    }
    a {
      text-decoration: none;
      &:hover, &:focus {
        text-decoration: none;
      }
    }

  }
}

////////////////////////////////////////////////////////////////////
// Mobile Navigation
////////////////////////////////////////////////////////////////////
header {
  nav {
    font-size: $heading-font-size;
    display: block;
    @include media ($md-screen-up) {
      display: none;
    }
  }
  .menu-anchor {
    position: absolute;
    top: 44px;
    right: 20px;
    width: 35px;
    height: 25px;
    @include media($xs-screen-up) {
      top: 63px;
    }
  }
  #js-mobile-menu span,
  #js-mobile-menu span:before,
  #js-mobile-menu span:after {
    top: 10px;
    cursor: pointer;
    border-radius: 1px;
    height: 5px;
    width: 35px;
    background: $dark-gray;
    position: absolute;
    display: block;
    @include media ($md-screen-up) {
      display: none;
    }
    content: '';
  }
  #js-mobile-menu span:before {
    top: -10px;
  }
  #js-mobile-menu span:after {
    bottom: -10px;
  }
  .navigation-menu {
    display: none;
  }
  .level_2 {
    li {
      a {
        color: $medium-gray;
      }
      @include nav-link-style;
    }
  }
  .navigation-wrapper {
    background-color: $white;
    .mod_navigation {
      a, span.active, span.trail {
        display: block;
        height: 40px;
        padding: 9px 20px;
      }
    }
    .level_1 {
      li {
        a {
          &:hover {
            background-color: $medium-gray;
          }
        }
        span.active {
          background-color: $medium-gray;
        }
        @include nav-link-style;
      }
    }
  }
}
