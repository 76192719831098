////////////////////////////////////////////////////////////////////
//Footer
////////////////////////////////////////////////////////////////////
footer {
  background-color: $white;
  @include outer-container();
  display: block;
  padding: 4px 20px;
  @include media ($md-screen-up) {
    display: none;
  }
}