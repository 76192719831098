////////////////////////////////////////////////////////////////////
//Navigation link styles
////////////////////////////////////////////////////////////////////
@mixin nav-link-style() {
  a:active,
  span.active,
  a.trail,
  a:focus,
  a:hover {
    text-decoration: none;
    background-color: $medium-white;
  }
}

@mixin work-nav-link-style() {
  a:active,
  span.active,
  a.trail,
  a:focus,
  a:hover {
    text-decoration: none;
    color: $dark-gray;
  }
}