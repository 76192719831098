@import "../../../../vendor/neat/app/assets/stylesheets/neat-helpers"; // or "../neat/neat-helpers" when not in Rails

////////////////////////////////////////////////////////////////////
// Neat Breakpoints
////////////////////////////////////////////////////////////////////

$xs-screen: em(480);
$sm-screen: em(780);
$md-screen: em(990);
$lg-screen: em(1200);

$max-width: em(1200) !global;

$xs-screen-up: new-breakpoint(min-width $xs-screen 12);
$sm-screen-up: new-breakpoint(min-width $sm-screen 12);
$md-screen-up: new-breakpoint(min-width $md-screen 12);
$lg-screen-up: new-breakpoint(min-width $lg-screen 12);
$special-point:new-breakpoint(min-width $max-width 12);

////////////////////////////////////////////////////////////////////
// development settings
////////////////////////////////////////////////////////////////////

//$visual-grid: yes !global;
$visual-grid-color: #5cff22 !global;
$visual-grid-opacity: 1 !global;

////////////////////////////////////////////////////////////////////
// Override Neat's grid settings
////////////////////////////////////////////////////////////////////

$column: golden-ratio(1em, 3) !default; // Column width
$gutter: golden-ratio(1em, 3) !default; // Gutter between each two columns

////////////////////////////////////////////////////////////////////
// Set Bourbon's flex-grid variables to match what Neat is doing
// http://bourbon.io/docs/#flex-grid
////////////////////////////////////////////////////////////////////

$fg-column: $column;
$fg-gutter: $gutter;