////////////////////////////////////////////////////////////////////
// Page Styles
////////////////////////////////////////////////////////////////////

// Page Home
.home #main {
  figure {
    //padding-left: 40px;
    margin-top: -10%;
  }
}

// Page Newsletter
.newsletter #main {
  #two-thirds {
    .mod_article {
      margin-top: 32px;
    }
  }
}

// News module pages
.news #main {
  .layout_full, .layout_short {
    &.first {
      margin-top: 0;
    }
    margin-top: 20px;
    @include media ($sm-screen-up) {
      @include span-columns(9 of 9);
      @include omega();
    }
    .ce_text {
      @include media ($sm-screen-up) {
        @include span-columns(4.5 of 9);
      }
      p {
        //padding-top: 32px;
      }
    }
    figure {
      @include media ($sm-screen-up) {
        @include span-columns(4.5 of 9);
      }
      //figcaption {
      //  text-transform: lowercase;
      //  text-align: right;
      //  color: $white;
      //  padding-top: 8px;
      //  padding-right: 3px;
      //  height: 32px;
      //  font-size: 12px;
      //  letter-spacing: 3px;
      //  background-color: $darker-gray;
      //  @include media ($xs-screen-up) {
      //    font-size: 20px;
      //    padding-top: 2px;
      //  }
      //}
    }
  }
}

//Newsletter archive
.ce_text li {
  font-family: $heading-font-family;
  text-transform: lowercase;
}

.ce_text {
  figure {
    @include media ($sm-screen-up) {
      &.float_right {
        float: right;
        padding-left: 20px;
      }
      &.float_left {
        float: left;
        padding-right: 20px;
      }
    }
  }
}

// Event pages
.event #main {
  .ce_text {
    margin-top: 50px;
    figure {
      @include media ($sm-screen-up) {
        &.float_right {
          float: right;
          padding-left: 20px;
        }
        &.float_left {
          float: left;
          padding-right: 20px;
        }
      }
    }
  }
}

#showcase {
  .mod_eventlist {
    > h1 {
      text-transform: lowercase;
    }
  }
}

.mod_eventlist {
  .event.layout_teaser {
    padding-bottom: 20px;
    &:last-child {
      padding-bottom: 0;
    }
  }
}

// inuit Module
.mod_inuit_module_artist_list,
.mod_inuit_module_topic_list,
.mod_inuit_module_region_list,
.mod_inuit_module_exhibition_list {
  .work-nav {
    font-family: $heading-font-family;
    ul {
      text-transform: lowercase;
      display: none;
      @include media ($sm-screen-up) {
        display: block;
      }
      li {
        > a, > span {
          text-transform: lowercase;
          color: $medium-gray;
        }
      }
    }
    .toggler-work {
      cursor: pointer;
      display: block;
      text-transform: uppercase;
      &.show::before {
        content: "\25BA";
        margin-right: em(10);
      }
      &.hidden::before {
        content: "\25BC";
        margin-right: em(10);
      }
      @include media ($sm-screen-up) {
        display: none;
      }
    }
    padding-bottom: 20px;
    li {
      height: 40px;
      padding: 9px 0;
      &.wrap {
        height: 68px;
      }
    }
    @include media ($sm-screen-up) {
      @include span-columns(3 of 9);
      padding-bottom: 0;
      li {
        padding: 0;
        height: auto;
        &.wrap {
          height: auto;
        }
      }
    }
  }
  div.work {
    position: relative;
    img {
      padding: 20px;
    }
    .meta {
      padding: 0 20px;
      p {
        margin: 0;
      }
    }
    @include media ($sm-screen-up) {
      @include span-columns(6 of 9);
    }
    .content-slider {
      position: relative;
    }
  }
  .ce_sliderStart {
    position: relative;
  }
  ul li {
    @include work-nav-link-style;
    span, a {
      text-transform: uppercase;
    }
  }
  nav.slider-control {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    .slider-menu {
      display: none;
    }
    a {
      &:hover, &:focus {
        text-decoration: none;
      }
      span.icon {
        font-size: 60px;
        line-height: 60px;
        height: 60px;
        width: 60px;
      }
      &.slider-prev,
      &.slider-next {
        position: absolute;
        top: 50%;
        margin-top: -35px;
      }
      &.slider-next span.icon {
        &:before {
          content: "\203A";
        }
      }
      &.slider-prev span.icon {
        &:before {
          content: "\2039";
        }
      }
    }
  }
}
