////////////////////////////////////////////////////////////////////
//global styles
////////////////////////////////////////////////////////////////////
#container {
  @include outer-container;
}

#container #main {
  background-color: $white;
  margin-top: 60px;
  padding: 20px;
  @include span-columns(12);
  @include media($md-screen-up) {
    @include span-columns(9);
    @include shift(3);
  }
}

#container #left {
  margin-top: -105px;
  padding: 30px 20px 0;
  background-color: $white;
  @include span-columns(3);
  @include shift(-12);
  display: none;
  @include media ($md-screen-up) {
    display: block;
  }
}

////////////////////////////////////////////////////////////////////
//Layout one-two thirds
////////////////////////////////////////////////////////////////////
.one-two-thirds #main div.mod_article {
  @include span-columns(9 of 9);
  div {
    &.ce_text,
    &.ce_form,
    &.ce_image {
      &:first-child {
        @include media($sm-screen-up) {
          @include span-columns(3 of 9);
        }
      }
      &:nth-child(2) {
        margin-top: 30px;
        @include media($sm-screen-up) {
          @include span-columns(6 of 9);
        }
      }
    }
  }
}

////////////////////////////////////////////////////////////////////
//Layout half half
////////////////////////////////////////////////////////////////////
.half-half #main div.mod_article {
  @include span-columns(9 of 9);
  div {
    &.ce_text,
    &.ce_form,
    &.ce_image {
      &:first-child {
        @include media($sm-screen-up) {
          @include span-columns(4.5 of 9);
        }
      }
      &:nth-child(2) {
        margin-top: 30px;
        @include media($sm-screen-up) {
          @include span-columns(4.5 of 9);
        }
      }
    }
  }
}

////////////////////////////////////////////////////////////////////
//Layout one-one-one-thirds
////////////////////////////////////////////////////////////////////
.one-one-one-thirds #main div.mod_article {
  @include span-columns(9 of 9);
  div {
    &.ce_text,
    &.ce_form,
    &.ce_image {
      &:first-child {
        @include media($sm-screen-up) {
          @include span-columns(3 of 9);
        }
      }
      &:nth-child(2) {
        margin-top: 30px;
        @include media($sm-screen-up) {
          @include span-columns(3 of 9);
        }
      }
      &:nth-child(3) {
        margin-top: 30px;
        @include media($sm-screen-up) {
          @include span-columns(3 of 9);
        }
      }
    }
  }
}

////////////////////////////////////////////////////////////////////
//Desktop Navigation
////////////////////////////////////////////////////////////////////
#container #left {
  nav {
    a, span.active, span.trail {
      display: block;
      padding-left: 4px;
      margin-right: -20px;
    }
    font-family: $nav-font-family;
    font-size: $heading-font-size;
    @include nav-link-style;
    .level_1 {
      text-transform: uppercase;
      > li {
        padding-bottom: 15px;
      }
    }
    .level_2 {
      text-transform: lowercase;
      li {
        a {
          color: $medium-gray;
        }
        @include nav-link-style;
      }
    }
  }
}

////////////////////////////////////////////////////////////////////
//Logo left
////////////////////////////////////////////////////////////////////
#container #left {
  #logo_desktop {
    padding-bottom: 40px;
    position: relative;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

////////////////////////////////////////////////////////////////////
//body
////////////////////////////////////////////////////////////////////
body {
  background-color: $base-background-color;
  margin: 0;
  .invisible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
  }
}

img {
  height: auto;
  width: 100%;
}

.ce_download {
  img {
    width: auto;
  }
}
