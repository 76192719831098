// Breakpoints
$medium-screen: 600px;
$large-screen: 900px;

// Typography
$base-font-family: 'EB Garamond', serif;
$heading-font-family: 'IM Fell DW Pica SC', serif;
$logo-font-family: $heading-font-family;
$nav-font-family: $heading-font-family;

// Font Sizes
$base-font-size: 1em;
$heading-font-size: 1.125em;
$logo-font-size: 120px;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$red: #ff0000;
$black: #000;
$darker-gray: #575756;
$dark-gray: #636363;
$medium-gray: #999;
$warm-gray: #ebe9e4;
$light-gray: #ddd;
$lighter-gray: #c6c6c6;
$medium-white: #f7f7f7;
$white: #fff;

// Font Colors
$base-font-color: $dark-gray;
$action-color: $dark-gray;
$first-logo-color: $lighter-gray;
$second-logo-color: $darker-gray;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: $medium-white;
//$secondary-background-color: tint($base-border-color, 75%);
$header-background-color: $warm-gray;
$secondary-background-color: $white;

// Forms
$form-box-shadow: inset 0 1px 3px rgba($black, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;
